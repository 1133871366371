import { mapGetters } from 'vuex'
import ConfirmModal from '../confirm_modal/confirm_modal.vue'
import { library } from '@fortawesome/fontawesome-svg-core'
import { faStar } from '@fortawesome/free-solid-svg-icons'
import {
  faStar as faStarRegular
} from '@fortawesome/free-regular-svg-icons'

library.add(
  faStar,
  faStarRegular
)

const FavoriteButton = {
  props: ['status', 'loggedIn'],
  components: {
    ConfirmModal
  },
  data () {
    return {
      animated: false,
      showingConfirmDialog: false
    }
  },
  methods: {
    favorite () {
      if (!this.status.repeated && this.shouldConfirmFavorite) {
        this.showConfirmDialog()
      } else {
        this.doFavorite()
      }
    },
    doFavorite () {
      if (!this.status.favorited) {
        this.$store.dispatch('favorite', { id: this.status.id })
      } else {
        this.$store.dispatch('unfavorite', { id: this.status.id })
      }
      this.animated = true
      setTimeout(() => {
        this.animated = false
      }, 500)
    },
    showConfirmDialog () {
      this.showingConfirmDialog = true
    },
    hideConfirmDialog () {
      this.showingConfirmDialog = false
    }
  },
  computed: {
    ...mapGetters(['mergedConfig']),
    remoteInteractionLink () {
      return this.$store.getters.remoteInteractionLink({ statusId: this.status.id })
    },
    shouldConfirmRepeat () {
      return this.mergedConfig.modalOnRepeat
    }
  }
}

export default FavoriteButton
